import axios from 'axios'
import store from '../store'

let getAllLocationAccountsToken = null

export default {
  methods: {
    async getAccount(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async getAllChannelAccounts(channelId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/channel/${channelId}/admins`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async getAllLocationAccounts(
      locationId,
      q = '',
      page = 0,
      itemsPerPage = 0
    ) {
      if (getAllLocationAccountsToken) {
        getAllLocationAccountsToken.cancel()
      }
      getAllLocationAccountsToken = axios.CancelToken.source()
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/location/${locationId}/all`,
        {
          cancelToken: getAllLocationAccountsToken.token,
          params: {
            q: q,
            page: page,
            itemsPerPage: itemsPerPage,
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async getLocationAccounts(
      locationId,
      searchTerm,
      page = 1,
      itemsPerPage = 25
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/account/location/${locationId}`,
        {
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationAccountGroups(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/groups`,
        {
          params: {
            locationId: locationId,
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    /**
     * Add new location account
     * @param {Object} accountRoleRights
     */
    async saveAccount(accountRoleRights) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account`,
        accountRoleRights,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    /**
     * Update account rights
     * @param {Object} accountRoleRights
     */
    async updateAccount(accountId, accountRoleRights) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/${accountId}`,
        accountRoleRights,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async createAccountGroup(accountGroup) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/group`,
        accountGroup,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    async updateAccountGroup(accountGroup) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/group/${accountGroup.Id}`,
        accountGroup,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    // Delete account
    async deleteAccount(accountId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/${accountId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },

    // Delete account group
    async deleteAccountGroup(groupId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/account/group/${groupId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          },
        }
      )
      return response
    },
  }
}
