<template>
  <ui-component-modal
    modalTitle="Share note"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="shareNote"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <table v-if="note && profile" class="table is-fullwidth is-note">
        <tbody>
          <tr>
            <td>
              <label
                ><input
                  type="checkbox"
                  v-model="mSendNoteToSelf"
                  :checked="sendNoteToSelf"
                />
                <span>{{
                  $t('Components.Notes.NoteItem.Table_Note-to-self')
                }}</span></label
              >
            </td>
          </tr>
          <tr>
            <td>
              <span>{{
                $t('Components.Notes.NoteItem.Table_Select-group')
              }}</span>
              <div v-for="group in groups" :key="group.Id">
                <label class="checkbox"
                  ><input
                    type="checkbox"
                    v-model="groupIds"
                    :value="group.Id"
                    @click="setGroupAccountIds(group)"
                  />
                  {{ group.Name }} ({{ group.Accounts.length }}
                  {{ $t('Components.Notes.NoteItem.Table_Accounts') }})
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <label class="checkbox">
                <input type="checkbox" v-model="showAllProfiles" />
                {{ $t('Components.Notes.NoteItem.Table_Select-colleagues') }}
              </label>
              <div
                v-if="showAllProfiles && accounts.length > 1"
                class="select is-multiple is-fullwidth"
              >
                <div v-if="isLoadingAccounts">
                  <progress
                    class="progress is-small is-primary"
                    max="100"
                  ></progress>
                </div>

                <select multiple size="5" v-model="accountIds">
                  <option
                    v-for="account in accounts"
                    :key="account.Id"
                    :value="account.ProfileId"
                    >{{ account.FullName }}</option
                  >
                </select>

                <div class="is-size-7 is-italic">
                  {{ $t('Components.Notes.NoteItem.Table_Use-CTRL') }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import accountProvider from '@/providers/account'
import noteProvider from '@/providers/note'

export default {
  components: {},

  props: {
    note: {
      type: Object,
      default: null
    },

    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      accountIds: [],
      accounts: [],
      groups: [],
      groupIds: [],
      isLoadingAccounts: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mSendNoteToSelf: this.sendNoteToSelf,
      sendNoteToAll: false,
      showAllProfiles: false
    }
  },

  computed: {
    ...mapGetters({
      profile: 'getProfile'
    }),

    colleagues() {
      let self = this
      let colleagues = []

      if (self.accounts && self.accounts.length > 0) {
        colleagues = self.accounts.filter(a => a.ProfileId !== self.profile.Id)
      }

      return colleagues
    },

    uniqueAccountIds() {
      return this.accountIds.filter(function(item, index, inputArray) {
        return inputArray.indexOf(item) == index
      })
    },

    sendNoteToSelf() {
      let result = false
      if (this.profile) {
        result = this.checkIfNotified(this.profile.Id)
      }
      return result
    }
  },

  watch: {
    mSendNoteToSelf: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.accountIds.push(this.profile.Id)
        } else {
          let accountIndex = this.accountIds.findIndex(
            a => a === this.profile.Id
          )
          if (accountIndex > -1) {
            this.accountIds.splice(accountIndex, 1)
          }
        }
      }
    },

    sendNoteToAll: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal) {
          let colleagueIds = this.colleagues.map(a => a.ProfileId)
          this.accountIds = this.accountIds.concat(colleagueIds)
        } else {
          this.accountIds = []
          if (this.mSendNoteToSelf) {
            this.accountIds.push(this.profile.Id)
          }
        }
      }
    },

    showAllProfiles: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal) {
          this.sendNoteToAll = false
        } else {
          this.accountIds = []
          if (this.mSendNoteToSelf) {
            this.accountIds.push(this.profile.Id)
          }
        }
      }
    }
  },

  created() {
    this.getLocationAccounts()
    this.getLocationAccountGroups()
  },

  methods: {
    getLocationAccounts() {
      if (this.accounts.length === 0) {
        this.isLoadingAccounts = true
        let searchTerm = ''
        let page = 0

        accountProvider.methods
          .getAllLocationAccounts(this.note.LocationId, searchTerm, page)
          .then(response => {
            if (response.status === 200) {
              this.accounts = response.data.Results

              this.mSendNoteToSelf = this.checkIfNotified(this.profile.Id)

              this.accountIds = this.accountIds.concat(
                this.note.Notifications.filter(
                  n => n.ProfileId !== this.profile.Id
                ).map(n => n.ProfileId)
              )
            }
          })
          .finally(() => {
            this.isLoadingAccounts = false
          })
      }
    },

    getLocationAccountGroups() {
      accountProvider.methods
        .getLocationAccountGroups(this.note.LocationId)
        .then(response => {
          if (response.status === 200) {
            this.groups = response.data
          }
        })
    },

    setGroupAccountIds(group) {
      let groupIndex = this.groupIds.findIndex(g => g === group.Id)
      if (groupIndex === -1) {
        for (let i = 0; i < group.Accounts.length; i++) {
          this.accountIds.push(group.Accounts[i].ProfileId)
        }
      } else if (groupIndex > -1) {
        this.accountIds.splice(groupIndex, 1)
      }
    },

    checkIfNotified(profileId) {
      let self = this
      let notified = false

      let notificationsIndex = self.note.Notifications.findIndex(
        n => n.ProfileId === profileId
      )
      if (notificationsIndex > -1) {
        notified = true
      }

      return notified
    },

    shareNote() {
      let self = this

      noteProvider.methods
        .shareNote(self.note.Id, self.note.LocationId, self.uniqueAccountIds)
        .then(response => {
          if (response.status === 200) {
            self.$emit('noteShared', response.data)

            self.isSavingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch(error => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    }
  }
}
</script>
